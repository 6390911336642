/* https://www.jeffedmondson.dev/react-protected-routes/ */
import React, {useState, createContext, useContext, useEffect} from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
	const [authed, setAuthed] = useState(false);
	const [pro, setPro] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		authCheck().then((response) => {
			if (response.status === 200) {
				response.json().then((json) => {
					if (json.role === "AUTHED") {
						setAuthed(true);
						setLoading(false);

					} else if (json.role === "PRO") {
						setAuthed(true);
						setPro(true);
						setLoading(false);

					} else {
						setAuthed(false);
						setLoading(false);
					}
				});
			}
		});
	}, []);

	const authCheck = async () => {
		return new Promise((resolve, reject) => {
			fetch("/api/auth-check", {
				method: "GET",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
				}

			}).then((response => {
				resolve(response);
			}));
		});
	};

	return (
		<AuthContext.Provider value={{authed, setAuthed, pro, setPro, loading}}>
			{children}
		</AuthContext.Provider>
	);
}

export const useAuth = () => useContext(AuthContext);