import "./checkout.css";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";

const Checkout = () => {
	const navigate = useNavigate();
	const {
		state: {
			clientSecret,
		}
	} = useLocation();

	const [pending, setPending] = useState(false);

	const [name, setName] = useState("");
	const [messages, _setMessages] = useState('');

	useEffect(() => {
		getAccount();
	}, []);

	// helper for displaying status messages.
	const setMessage = (message) => {
		_setMessages(`${messages}\n\n${message}`);
	}

	// Initialize an instance of stripe.
	const stripe = useStripe();
	const elements = useElements();

	if (!stripe || !elements) {
		// Stripe.js has not loaded yet. Make sure to disable
		// form submission until Stripe.js has loaded.
		return '';
	}

	// When the subscribe-form is submitted we do a few things:
	//
	//   1. Tokenize the payment method
	//   2. Create the subscription
	//   3. Handle any next actions like 3D Secure that are required for SCA.
	const handleSubmit = async (e) => {
		e.preventDefault();
		setPending(true);

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);

		// Use card Element to tokenize payment details
		const {error} = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: cardElement,
				billing_details: {
					name: name,
				}
			}
		});

		if (error) {
			// show error and collect new card details.
			setMessage(error.message);
			setPending(false);
			return;
		}

		navigate("/settings", {replace: false});
	}

	function getAccount() {
		fetch("/api/account", {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}
		}).then((response) => {
				if (response.status === 200) {
					response.json().then((json) => {
						setName(json.first_name + " " + json.last_name);
					});
				}
			});
	}

	return (
		<div className="checkoutContainer">
			<p className="formTitle">Checkout</p>

			<form className="subscribeForm" onSubmit={handleSubmit}>
				<div className="nameSection">
					<p>Full Name</p>
					<input className="name" type="text" id="name" value={name}
						   onChange={(e) => setName(e.target.value)}/>
				</div>

				<div className="cardSection">
					<p>Card Number</p>
					<div className="cardInfoContainer">
						<CardElement className="cardInfo"/>
					</div>
				</div>

				<div className="checkoutSection">
					<button disabled={pending} className="checkoutButton">Checkout</button>
					<button className="checkoutButton" onClick={() => navigate("/pricing")}>Back</button>
					<div>{messages}</div>
				</div>
			</form>
		</div>
	);
};

export default Checkout;