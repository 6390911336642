import React from "react";
import {Navigate, Route} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

const ProtectedRoute = ({needsPro, children}) => {
	const {authed, pro} = useAuth();

	if (needsPro && !pro) {
		return <Navigate to="/pricing"/>;
	}

	if (authed) {
		return children;
	}

	return <Navigate to="/login"/>;
};

export default ProtectedRoute;