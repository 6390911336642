import {useState} from "react";

import "./seatShuffler.css";

const SeatShuffler = () => {
	const [names, setNames] = useState("");

	const submit = (event) => {
		event.preventDefault();

		let splitNames = names.split("\n").filter(e => String(e).trim());
		setNames(shuffle(splitNames).join("\n"));
	}

	const shuffle = (a) => {
		let j, x, i;
		for (i = a.length - 1; i > 0; i--) {
			j = Math.floor(Math.random() * (i + 1));
			x = a[i];
			a[i] = a[j];
			a[j] = x;
		}
		return a;
	}

	let splitNames = names.split("\n").filter(e => String(e).trim());

	return (
		<div className="seatShufflerContainer">
			<div className="namesForm">
				<div>
					<label htmlFor="players">Enter player names, one per line:</label>
					<div className="grow-wrap">
						<textarea
							name="players"
							className="playerNames"
							rows={10}
							cols={40}
							placeholder={"John\nJoe\nRyan"}
							value={names}
							onChange={(e) => setNames(e.target.value)}
						/>
					</div>
				</div>

				<button className="submitButton lightButtonNoBorder" onClick={submit}>Shuffle</button>
			</div>

			<div className="seatList">
				{splitNames.length >= 1 ? <p>Button: {splitNames[0]}</p> : ""}
				{splitNames.length >= 3 ? <p>Small Blind: {splitNames[2]}</p> : ""}
				{splitNames.length >= 2 ? <p>Big Blind: {splitNames[1]}</p> : ""}
				{splitNames.length >= 4 ? <p>Under the Gun: {splitNames[3]}</p> : ""}
				{splitNames.length >= 8 ? <p>Under the Gun + 1: {splitNames[7]}</p> : ""}
				{splitNames.length > 9 ? (
						splitNames.map((elem, index) => {
							if (index < 9) return;
							return (
								<p>Under the Gun + {index - 7}: {splitNames[index]}</p>
							);
						})
					)
					: ""
				}
				{splitNames.length >= 6 ? <p>Middle Position: {splitNames[5]}</p> : ""}
				{splitNames.length >= 9 ? <p>Lojack: {splitNames[8]}</p> : ""}
				{splitNames.length >= 7 ? <p>Hijack: {splitNames[6]}</p> : ""}
				{splitNames.length >= 5 ? <p>Cut Off: {splitNames[4]}</p> : ""}

			</div>
		</div>
	);
}

export default SeatShuffler;