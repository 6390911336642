import "./settings.css";
import Page from "../page/page";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Modal from "../modal/modal";

const AccountSubscription = ({subscription}) => {
	let d = new Date(subscription.current_period_end * 1000);
	let options = {year: 'numeric', month: 'long', day: 'numeric'};
	let dateStr = d.toLocaleString("en-US", options);

	const navigate = useNavigate();

	const [showCancelModal, setShowCancelModal] = useState(false);
	const [pending, setPending] = useState(false);

	const handleCancelClick = (id) => {
		setPending(true);

		fetch("/api/stripe/cancel-subscription", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				subscriptionId: id
			})
		}).then((resp) => {
			window.location.reload();
		});
	};

	return (
		<div className="subscriptionContainer">
			{
				/*
				<p>
				<a href={`https://dashboard.stripe.com/test/subscriptions/${subscription.id}`}>
					{subscription.id}
				</a>
				</p>
				*/
			}

			<p>Status: {subscription.status}</p>
			<p>Card: {subscription.default_payment_method?.card?.last4}</p>
			<p>Current period ends {dateStr}</p>

			<button disabled={pending} onClick={() => setShowCancelModal(true)}>Cancel</button>

			{/* <Link to={{pathname: '/change-plan', state: {subscription: subscription.id }}}>Change plan</Link><br /> */}

			<Modal show={showCancelModal} handleClose={() => setShowCancelModal(false)}>
				<p>Are you sure you want to cancel your subscription? You cannot undo this!</p>
				<button disabled={pending} onClick={(e) => {
					e.preventDefault();
					handleCancelClick(subscription.id);
				}}>Cancel
				</button>
			</Modal>
		</div>
	)
}

const Settings = () => {
	const [activeSubscriptions, setActiveSubscriptions] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			const {subscriptions} = await fetch("/api/stripe/subscriptions").then(r => r.json());
			setActiveSubscriptions(subscriptions.data.filter((s) => s.status === "active"));
		}
		fetchData();
	}, []);

	return (
		<Page>
			<div className="settingsContainer">
				<p className="settingsTitle">Settings</p>
				<div className="subscriptionsContainer">
					<p className="sectionHeader">PRO Subscription</p>
					<div className="subscriptions">
						{
							activeSubscriptions.length > 0 ? activeSubscriptions.map(s => {
									return <AccountSubscription key={s.id} subscription={s}/>
								}) :
								<div className="subscriptionContainer">
									<p>None</p>
									<button onClick={() => navigate("/pricing")}>Upgrade to PRO</button>
								</div>
						}
					</div>

				</div>
			</div>
		</Page>
	);
};

export default Settings;