import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./hooks/useAuth";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const root = ReactDOM.createRoot(document.getElementById('root'));

fetch("/api/stripe/config")
	.then((response) => response.json())
	.then((data) => {
		const stripePromise = loadStripe(data.publishableKey);

		root.render(
			<React.StrictMode>
				<AuthProvider>
					<Elements stripe={stripePromise}>
						<App/>
					</Elements>
				</AuthProvider>
			</React.StrictMode>
		);
	});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();