import React, {useEffect, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";

import "./accountWidget.css";

const AccountWidget = () => {
	useEffect(() => {
		getAccount();
	}, []);

	const [username, setUsername] = useState("Not Logged In.");

	const [displayDropdown, setDisplayDropdown] = useState(false);

	const navigate = useNavigate();

	function getAccount() {
		fetch("/api/account", {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}
		})
			.then((response) => {
				if (response.status === 200) {
					response.json().then((json) => {
						setUsername(json.username);
					});
				}
			});
	}

	const logoutClick = () => {
		fetch("/api/logout", {
			method: "POST",
			headers: {
				"Content-type": "application/json; charset=UTF-8"
			}

		}).then((response) => {
			if (response.status === 200) {
				document.cookie = "login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
				navigate('/');
				window.location.reload();
			}
		});
	}

	const handleClick = () => {
		setDisplayDropdown(!displayDropdown);
	};

	return (
		<div className="accountWidgetContainer" onClick={handleClick}>
			<div className={"accountWidgetContent" + (displayDropdown ? " dropped" : "")}>
				<img className="pfp editedIcon" hidden={username === "Not Logged In."} src="/profile.png"
					 alt="Profile picture"/>
				<p>{username}</p>
			</div>

			<div className="dropdown" hidden={!displayDropdown}>
				<div className="dropdownItem" hidden={username === "Not Logged In."} onClick={() => navigate("/settings")}>
					<p className="dropdownItemText">
						Settings
					</p>
				</div>

				<div className="dropdownItem" hidden={username === "Not Logged In."} onClick={logoutClick}>
					<p className="dropdownItemText">
						Logout
					</p>
				</div>

				<div hidden={username !== "Not Logged In."} className="dropdownItem"
					 onClick={() => navigate("/signup")}>
					<p className="dropdownItemText">
						Sign Up
					</p>
				</div>

				<div hidden={username !== "Not Logged In."} className="dropdownItem" onClick={() => navigate("/login")}>
					<p className="dropdownItemText">
						Login
					</p>
				</div>
			</div>
		</div>
	);
}

export default AccountWidget;